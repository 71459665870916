/* eslint-disable */
import React, { Fragment } from "react";
import Manual from "../components/ManualList/Manual";

const Manuallist = () => {
  return (
    <main>
      <section id="sect1">
        <div className="Wrap">
          <h2>매뉴얼</h2>
          <p>셀디와 함께 수출 고민 쉽게 해결하세요!</p>
        </div>
      </section>
      <div id="sect">
        <Manual />

        
      </div>
    </main>
  );
};

export default Manuallist;
