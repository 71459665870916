/* eslint-disable */
import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="Wrap flexbox_sb">
        <div className="col1 flexbox_fs">
          <div className="logo_w">
            <img src="/images/mainlogo_w.svg" alt=""></img>{" "}
          </div>

          <div className="detail">
            <strong>(주) 셀디코리아</strong>
            <p className="company">
              대표 : 이창헌 <span></span>
              서울시 테헤란로 <strong>26</strong>길 <strong>14 7106</strong>
              <br />
              사업자등록번호 : <strong>568-88-02012</strong>
              <br />
              전화 : <strong>02 - 6958 - 8556</strong>
              <span></span>
              팩스 : <strong>0504 - 051 - 2750</strong>
            </p>
            <p className="copyright">ⓒ SELDIKOREA All Rights Reserved.</p>
          </div>
        </div>
        <div className="col2">
          <p>상담문의</p>
          <h2>02.6958.8556</h2>
          <p>
            평일 <span>09:00~18:00 </span>(점심 <span>11:30 ~ 12:30</span>)
            <br />
            토/일/공휴일 휴무
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
