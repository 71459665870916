/* eslint-disable */
import React, { Fragment } from "react";
import "./css/Reset.css";
import "./css/App.css";
import Header from "./pages/Header"
import ManualContain from "./pages/ManualContain"
import Footer from "./pages/Footer";

function App() {
  return (
    <Fragment>
      <Header />
      <ManualContain />
      <Footer />
    </Fragment>
  );
}

export default App;
