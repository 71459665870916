/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { getBaseManualUrl } from "../../config/index";

const Main = () => {
  const [manual, setManual] = useState([]);

  useEffect(() => {
    fetch(`${getBaseManualUrl()}/v1/manual`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res.result.items);
        setManual(res.result.items);
      });
  }, []);

  const Manual = () => {
    const ManualList = (titles) => {
        return titles.map((titles, index) => {
          return (
            <div key={index} className="boxContainer">
              <h4>{titles.title}</h4>
              <div className="boxContent flexbox">
                <p>{titles.subtitle}</p>
                {titles.caution && (
                  <a href="#" className="caution flexbox">
                    <span>
                      <img src={titles.cautionIcon} alt="" />
                    </span>
                    <p>{titles.caution}</p>
                  </a>
                )}
              </div>
              <div className="box flexbox">
                {titles.cards.map((card, index) => {
                  return (
                    <div key={index} className="icon">
                      <a href={`${card.card_link}`} className="flexbox_sb">
                        <div className="icontext flexbox">
                          <span></span>
                          <p>{card.card_title}</p>
                        </div>
                        <img src={`${card.card_image}.svg`}></img>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        });
    };
    return manual.map((item,index) => {
      return (
        <section key={index}>
          <div className="Wrap">
            <article className="maintitle flexbox_sb">
              <div className="title">
                <h5>{item.main_title}</h5>
                <div>
                  {item.main_text.split(" ").map((textBr, index) => {
                    const enter = textBr === 0 ? 2 : 1 
                    return (
                      <span className="textBr" key={index}>
                        {textBr}{" "}
                        {index === enter && (
                          <span>
                            <img src={item.emoji} /><br/>
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="line">
                .................................................................................................................................................
              </div>
              <div className="illust">
                <img src={`${item.illust}.svg`}></img>
              </div>
            </article>
            <article>{ManualList(item.titles)}</article>
          </div>
        </section>
      );
    });
 
  };
  return (
    <Fragment>
      {Manual(setManual)}
      <div className="Wrap">
        <div className="top_btn">
          <a href="#top">
            <img src="/images/btn_top.svg" alt="" />
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Main;
