/* eslint-disable */
import React from "react";

const Header = () => {
    const subList = [
        { title: "셀디 소개" },
        { title: "수출 가이드" },
        { title: "매뉴얼" },
      ];
      const Menu = (allMenu) => {
        return allMenu.map((subItem, index)=>{
         
          // debugger
          // console.log("======>",subItem)
          
          return(
            <li key={index} className="mainmenu">
              <a href="#" >
                {subItem.title} <span></span>
              </a>
            </li>
          )
        })
      };
  return (
    <header>
      <div className="firstheader Wrap flexbox_sb">
        <div className="flexbox">
          <h1>
            <img src={process.env.PUBLIC_URL + "/images/mainlogo.svg"} alt="" />
          </h1>
          <nav className="mainnav">
            <ul className="headernav flexbox_sb">
                {Menu(subList)}
            </ul>
          </nav>
        </div>
        <ul className="flexbox headerbtn">
          <li>
            <a href="#">로그인</a>
          </li>
          <li>
            <a href="#">문의하기</a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
